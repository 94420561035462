<template>
    <validation-observer tag="div" ref="observer">
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'crm_crm_statuses')">
                    <multiselect v-model="form.crm_crm_statuses"
                                 name="crm_crm_statuses"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
            <b-col cols="6">
                <b-form-group :label="$t(labelPrefix+'crm_aftersale_statuses')">
                    <multiselect v-model="form.crm_aftersale_statuses"
                                 name="crm_aftersale_statuses"
                                 :searchable="true"
                                 :options="Object.keys(getStatuses())"
                                 :multiple="true"
                                 select-label=""
                    ></multiselect>
                </b-form-group>
            </b-col>
        </b-row>

        <b-row>
            <b-col sm="12" class="text-xs-center mt-2">
                <b-row align-h="end">
                    <b-col sm="6" class="text-sm-right">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>

        </b-row>
    </validation-observer>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'SettingsCrmStatusMap',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            defaultForm: {
                crm_crm_statuses: null,
                crm_aftersale_statuses: null,
            },
            form: {},
            labelPrefix: 'settings.crm_status_map.label.',
        }
    },
    methods: {
        ...mapGetters('Settings', ['getCrmStatusMap']),
        ...mapGetters('CommonData', ['getStatuses']),
        shown() {
            this.loading = true
            const settingsCrmStatusMap = this.$store.dispatch('Settings/fetchCrmStatusMap');

            Promise.all([settingsCrmStatusMap]).then(() => {
                this.form = JSON.parse(JSON.stringify({...this.defaultForm, ...this.getCrmStatusMap()}))
                this.loading = false
            });
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.$store.dispatch('Settings/save', this.form).then((response) => {
                        this.afterSave(response)
                    }).catch(errors => {
                        this.$refs['observer'].setErrors(errors)
                        this.showValidationError()
                    })
                } else {
                    this.showValidationError()
                }
            })
        },
        afterSave(response) {
            if (response.status == 200 || response.status == 201) {
                this.$root.showMessage(
                    this.$t('common.form.title.success'),
                    this.$t('common.toasts.success_updated'),
                    'success'
                )
                this.shown()
            }
            this.$root.afterSettingsUpdate()
        },
    },
    mounted() {
        this.shown()
    }
}
</script>